<template>
  <div id="mentions_legales_hub">
    <MentionsLegalesHubDe v-if="country === 'de'" />
    <MentionsLegalesHubFr v-else />
  </div>
</template>

<script>
import MentionsLegalesHubFr from "@/components/legal/hub/MentionsLegalesHubFr.vue";
import MentionsLegalesHubDe from "@/components/legal/hub/MentionsLegalesHubDe.vue";

import { mapGetters } from "vuex";

/**
 * Affiche les mentions légales de Hub dans la bonne langue.
 */
export default {
  name: "MentionsLegalesHub",
  components: {
    MentionsLegalesHubFr,
    MentionsLegalesHubDe,
  },
  computed: {
    ...mapGetters(["country"]),
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_legal.scss";
</style>
