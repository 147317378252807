<template>
  <div
    id="mentions_legales_hub_de"
    class="text-regular"
  >
    <div class="bloc">
      <address>
        Der Internetauftritt
        <a
          :href="urlHub"
          target="_blank"
          rel="noreferrer noopener"
        >
          {{ urlHub }}
        </a>
        wird betrieben von der<br />
        LDE GmbH & Co.KG<br />
        Robert-Koch-Straße 35<br />
        77694 Kehl<br />
        Handelsregister: AG Freiburg HRA 370799
      </address>
      <p>vertreten durch die</p>
      <address>
        LDE Verwaltungs GmbH<br />
        Robert-Koch-Straße 35<br />
        77694 Kehl, Handelsregister<br />
        AG Freiburg HRB 372026
      </address>
      <p>vertreten durch den Geschäftsführer Frédéric FRITSCH.</p>
      <address>
        Tel.: +49 (0)7851 - 48 599-0<br />
        Fax +49 (0)7851-48 599-22<br />
        E-Mail: <a :href="`mailto:${mailLdeDe}`">{{ mailLdeDe }}</a>
      </address>
      <address>USt.-ID-Nr.: DE247102923</address>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Bitte beachten Sie folgende Hinweise:
      </h2>
      <p>
        Online-Streitschlichtung gemäß Art. 14 Abs. 1 ODR-VO: Die Europäische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit, die Sie hier finden:
        <a
          href="https://ec.europa.eu/consumers/odr/"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://ec.europa.eu/consumers/odr/
        </a>.
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        Hinweis gemäß § 36 Verbraucherstreitbeilegungsgesetz (VSGB):
      </h2>
      <p>
        LDE ist nicht zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle im Sinne
        des VSBG verpflichtet und wird an einem solchen Streitbeilegungsverfahren nicht teilnehmen.
      </p>
    </div>
  </div>
</template>

<script>
import config from "@/config";

/**
 * Affiche la page des mentions légales de Hub en allemand.
 */
export default {
  name: "MentionsLegalesHubDe",
  computed: {
    urlHub() {
      return config.urlDe.hub;
    },
    mailLdeDe() {
      return config.mailsDe.lde;
    },
  },
};
</script>

<style lang="scss"></style>
